import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Header from '../components/HeaderLH';
import { LayoutPDP } from '../components/LayoutPDP';
import PdpFooterLH from '../components/Pdp/PdpFooterLH';
import SEO from '../components/SEO/SEO';
import { useWindowWidthSize } from '../hooks/useWindowWidthSize';

import { LayoutWrapper } from '@cof/lighthouse-component-library';
import { Link } from '@cof/lighthouse-component-library';

import beliefHeroDesktop from '../assets/images/belief-hero-desktop.png';
import beliefHeroTablet from '../assets/images/belief-hero-tablet.png';
import beliefHeroMobile from '../assets/images/belief-hero-mobile.png';
import storyPic1 from '../assets/images/belief-story-jason.png';
import storyPic2 from '../assets/images/belief-story-sharee.png';
import checkNowCardImageDesktop from '../assets/images/belief-check-now-desktop.png';
import checkNowCardImageTablet from '../assets/images/belief-check-now-tablet.png';
import checkNowCardImageMobile from '../assets/images/belief-check-now-mobile.png';
import creditScoreCardImageDesktop from '../assets/images/belief-credit-score-desktop.png';
import creditScoreCardImageTablet from '../assets/images/belief-credit-score-tablet.png';
import creditScoreCardImageMobile from '../assets/images/belief-credit-score-mobile.png';

import './belief.scss';

const largeScreenBreakpoint = 1024;

const BeliefHeroBanner = ({ intl, windowWidth }) => {
  return (
    <div className="belief-header">
      <div className="belief-header-content">
        <h1 data-testid="banner-title">
          <FormattedMessage
            id={'pages.belief.hero-banner.title'}
            values={{
              whiteText: (
                <span className="hero-banner-white-text">
                  {intl.formatMessage({ id: 'pages.belief.hero-banner.values.whiteText' })}
                </span>
              ),
            }}
          />
        </h1>
        {windowWidth >= largeScreenBreakpoint && (
          <>
            <p className="desktop-view-subtext">
              <FormattedMessage id={'pages.belief.hero-banner.subtitleDesktopP1'} />
            </p>
            <p>
              <FormattedMessage id={'pages.belief.hero-banner.subtitleDesktopP2'} />
            </p>
          </>
        )}
        {windowWidth < largeScreenBreakpoint && (
          <p>
            <FormattedMessage id={'pages.belief.hero-banner.subtitle'} />
          </p>
        )}
      </div>
      <div className="belief-banner-image-box">
        <img src={beliefHeroDesktop} alt="" className="belief-banner-desktop" />
        <img src={beliefHeroTablet} alt="" className="belief-banner-tablet" />
        <img src={beliefHeroMobile} alt="" className="belief-banner-mobile" />
      </div>
    </div>
  );
};

BeliefHeroBanner.propTypes = {
  intl: intlShape,
  windowWidth: PropTypes.number,
};

const BeliefStories = ({ intl }) => {
  return (
    <div className="belief-stories-container">
      <h2>
        <FormattedMessage id={'pages.belief.true-stories.title'} />
      </h2>
      <p>
        <FormattedMessage id={'pages.belief.true-stories.subtitle'} />
      </p>
      <div className="belief-story-one">
        <div className="story-one-image-box">
          <img src={storyPic1} alt={intl.formatMessage({ id: 'pages.belief.true-stories.story1.person' })} />
        </div>
        <div className="story-one-contents">
          <h3>
            <FormattedMessage id={'pages.belief.true-stories.story1.person'} />
          </h3>
          <p className="story-one-quote">
            <FormattedMessage id={'pages.belief.true-stories.story1.title'} />
          </p>
          <p className="story-one-overview">
            <FormattedMessage id={'pages.belief.true-stories.story1.content'} />
          </p>
          <Link
            to={intl.formatMessage({ id: 'pages.belief.true-stories.story1.storyLink' })}
            preset="secondary"
            isButton
            newTab
            data-testid="read-more-button-one"
          >
            <FormattedMessage id={'pages.belief.true-stories.read-more-button'} />
          </Link>
        </div>
      </div>
      <div className="belief-story-two">
        <div className="story-two-contents">
          <h3>
            <FormattedMessage id={'pages.belief.true-stories.story2.person'} />
          </h3>
          <p className="story-two-quote">
            <FormattedMessage id={'pages.belief.true-stories.story2.title'} />
          </p>
          <p className="story-two-overview">
            <FormattedMessage id={'pages.belief.true-stories.story2.content'} />
          </p>
          <Link
            to={intl.formatMessage({ id: 'pages.belief.true-stories.story2.storyLink' })}
            preset="secondary"
            isButton
            newTab
            data-testid="read-more-button-two"
          >
            <FormattedMessage id={'pages.belief.true-stories.read-more-button'} />
          </Link>
        </div>
        <div className="story-two-image-box">
          <img src={storyPic2} alt={intl.formatMessage({ id: 'pages.belief.true-stories.story2.person' })} />
        </div>
      </div>
    </div>
  );
};

BeliefStories.propTypes = {
  intl: intlShape,
};

const BeliefCardTiles = ({ intl }) => {
  return (
    <div className="belief-card-tiles-container">
      <h2>
        <FormattedMessage id={'pages.belief.got-you-covered.title'} />
      </h2>
      <p>
        <FormattedMessage id={'pages.belief.got-you-covered.subtitle'} />
      </p>
      <div className="belief-first-card-tile">
        <div className="first-card-contents">
          <h3>
            <FormattedMessage id={'pages.belief.got-you-covered.browse-cards.heading'} />
          </h3>
          <p>
            <FormattedMessage id={'pages.belief.got-you-covered.browse-cards.subheading'} />
          </p>
          <Link
            to={intl.formatMessage({ id: 'pages.belief.got-you-covered.browse-cards.link' })}
            preset="secondary-alt-dark"
            isButton
            data-testid="browse-card-button-test"
          >
            <FormattedMessage id={'pages.belief.got-you-covered.browse-cards.button'} />
          </Link>
        </div>
      </div>
      <div className="belief-bottom-cards">
        <div className="belief-second-card-tile">
          <div className="second-card-contents">
            <h3>
              <FormattedMessage id={'pages.belief.got-you-covered.quick-check.heading'} />
            </h3>
            <p>
              <FormattedMessage
                id="pages.belief.got-you-covered.quick-check.subheading"
                values={{ sup1: <sup>1</sup> }}
              />
            </p>
            <Link
              to={intl.formatMessage({ id: 'pages.belief.got-you-covered.quick-check.link' })}
              preset="primary-alt"
              isButton
              newTab
              data-testid="quick-check-button-test"
            >
              <FormattedMessage id={'pages.belief.got-you-covered.quick-check.button'} />
            </Link>
          </div>
          <div className="card-tile-image-box">
            <img
              src={checkNowCardImageDesktop}
              alt={intl.formatMessage({ id: 'pages.belief.got-you-covered.quick-check.imageAlt' })}
              className="image-desktop"
              data-testid="check-now-desktop"
            />
            <img
              src={checkNowCardImageTablet}
              alt={intl.formatMessage({ id: 'pages.belief.got-you-covered.quick-check.imageAlt' })}
              className="image-tablet"
              data-testid="check-now-tablet"
            />
            <img
              src={checkNowCardImageMobile}
              alt={intl.formatMessage({ id: 'pages.belief.got-you-covered.quick-check.imageAlt' })}
              className="image-mobile"
              data-testid="check-now-mobile"
            />
          </div>
        </div>
        <div className="belief-third-card-tile">
          <div className="third-card-contents">
            <h3>
              <FormattedMessage id={'pages.belief.got-you-covered.credit-score.heading'} />
            </h3>
            <p>
              <FormattedMessage
                id="pages.belief.got-you-covered.credit-score.subheading"
                values={{ sup2: <sup>2</sup> }}
              />
            </p>
            <Link
              to={intl.formatMessage({ id: 'pages.pdp.content.credit-keeper.link' })}
              preset="primary-alt"
              isButton
              newTab
              data-testid="credit-score-button-test"
            >
              <FormattedMessage id={'pages.belief.got-you-covered.credit-score.button'} />
            </Link>
          </div>
          <div className="card-tile-image-box">
            <img
              src={creditScoreCardImageDesktop}
              alt={intl.formatMessage({ id: 'pages.belief.got-you-covered.credit-score.imageAlt' })}
              className="image-desktop"
              data-testid="credit-score-desktop"
            />
            <img
              src={creditScoreCardImageTablet}
              alt={intl.formatMessage({ id: 'pages.belief.got-you-covered.credit-score.imageAlt' })}
              className="image-tablet"
              data-testid="credit-score-tablet"
            />
            <img
              src={creditScoreCardImageMobile}
              alt={intl.formatMessage({ id: 'pages.belief.got-you-covered.credit-score.imageAlt' })}
              className="image-mobile"
              data-testid="credit-score-mobile"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BeliefCardTiles.propTypes = {
  intl: intlShape,
};

const BeliefLegal = () => {
  return (
    <div className="belief-legal-section">
      <p>
        <FormattedMessage id="pages.belief.legal-disclaimer.note1" values={{ sup1: <sup>1</sup> }} />
      </p>
      <p>
        <FormattedMessage id="pages.belief.legal-disclaimer.note2" />
      </p>
    </div>
  );
};

const Belief = injectIntl(({ intl }) => {
  const windowWidth = useWindowWidthSize();

  const beliefHeader = () => {
    return <Header intl={intl} hideLanguage={true} />;
  };

  const beliefFooter = () => {
    return <PdpFooterLH intl={intl} hidePromo={true} />;
  };

  return (
    <LayoutPDP containerClass="belief-container" CustomHeader={beliefHeader} CustomFooter={beliefFooter}>
      <LayoutWrapper className="belief-banner-wrapper">
        <BeliefHeroBanner intl={intl} windowWidth={windowWidth} />
      </LayoutWrapper>
      <LayoutWrapper className="belief-stories-wrapper">
        <BeliefStories intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="belief-card-tiles-wrapper">
        <BeliefCardTiles intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="belief-card-legal-wrapper">
        <BeliefLegal />
      </LayoutWrapper>
    </LayoutPDP>
  );
});

export default Belief;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.belief.seo.page-title" descriptionKey="pages.belief.seo.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
